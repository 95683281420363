<template>
  <div>
    <PageIntroduce
      :title="'风险历史'"
      :desc="'通过差距分析，逐项比对法规的条款要求，快速识别到产品法律风险'"
    />
    <PageTotalChart
      v-loading="!barSeriesArr.length"
      left-title="风险统计"
      :bar-series-arr="barSeriesArr"
      :backgrounds="['primary', 'danger', 'warning', 'light-warning', 'success']"
      :categories="['风险总数','严重风险数', '高风险数', '中风险数', '低风险数','极低风险数']"
    />
    <b-card>
      <div class="filter-wrap flex-between">
        <div class="flex-start search-filter-wrap">
          <el-input
            v-model="listQuery.SearchStr"
            width="180"
            placeholder="请输入内容"
            clearable
            @clear="handleFilter"
          />
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="handleFilter"
          >
            搜索
          </el-button>
        </div>
      </div>
      <div class="table-wrap">
        <FilterSelectList
          :list-query.sync="listQuery"
          :select-list.sync="selectList"
          @handleFilter="handleFilter"
        />
        <el-table
          v-loading="loading"
          border
          fit
          highlight-current-row
          :data="tableData"
          stripe
          style="width: 100%"
          @sort-change="sortChange"
        >
          <el-table-column
            prop="riskType"
            :sort-by="'risk_type'"
            width="120"
            sortable
            :show-overflow-tooltip="true"
            label="风险类别"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'riskType'"
                :select-list.sync="selectList"
                :label="'风险类别'"
                :value.sync="listQuery.riskType"
                :dropdown-options="riskTypeOptions"
                @handleFilter="handleFilter"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="explanation"
            label="风险描述"
          >
            <template slot-scope="{row}">
              <TableTips :text="row.explanation" />
            </template>
          </el-table-column>
          <el-table-column
            prop="riskObjectName"
            :sort-by="'risk_object_name'"
            width="120"
            sortable
            label="关联对象"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'riskObject'"
                :select-list.sync="selectList"
                :label="'关联对象'"
                :value.sync="listQuery.riskObject"
                :dropdown-options="riskObjectOptions"
                @handleFilter="handleFilter"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="riskLevel"
            :sort-by="'risk_level'"
            sortable
            width="115"
            label="风险等级"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'riskLevel'"
                :select-list.sync="selectList"
                :label="'风险等级'"
                :value.sync="listQuery.riskLevel"
                :dropdown-options="levelOptions"
                @handleFilter="handleFilter"
              />
            </template>
            <template slot-scope="{row}">
              <b-badge
                class="d-flex justify-content-center"
                style="align-items: center"
                pill
                :variant="levelColorObj[row.riskLevel]"
              >
                {{ levelObj[row.riskLevel] }}
              </b-badge>
            </template>
          </el-table-column>
          <el-table-column
            prop="riskSourceName"
            :sort-by="'risk_source_name'"
            sortable
            :show-overflow-tooltip="true"
            width="115"
            label="风险来源"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'riskSource'"
                :select-list.sync="selectList"
                :label="'风险来源'"
                :value.sync="listQuery.riskSource"
                :dropdown-options="riskSourceOptions"
                @handleFilter="handleFilter"
              />
            </template>
          </el-table-column>
          <el-table-column
            width="90"
            prop="createTime"
            label="产生时间"
          >
            <template slot-scope="scope">
              {{ formatDateStr(scope.row.createTime) }}
            </template>
          </el-table-column>
          <el-table-column
            width="115"
            prop="riskStatus"
            :sort-by="'risk_status'"
            sortable
            label="风险状态"
          >
            <template slot="header">
              <TableHeaderFilterDropdown
                :key-str="'riskStatus'"
                :select-list.sync="selectList"
                :label="'风险状态'"
                :value.sync="listQuery.riskStatus"
                :dropdown-options="riskStatusOptions"
                @handleFilter="handleFilter"
              />
            </template>
            <template slot-scope="{row}">
              {{ riskStatusObj[row.riskStatus] }}
            </template>
          </el-table-column>
          <el-table-column
            prop="disposalAdvice"
            label="处置建议"
          >
            <template slot-scope="{row}">
              <TableTips :text="row.disposalAdvice" />
            </template>
          </el-table-column>
          <el-table-column
            prop="assignUserName"
            :show-overflow-tooltip="true"
            label="发布人员"
          />
          <el-table-column
            prop="disposalUserNames"
            :show-overflow-tooltip="true"
            label="处理人员"
          />
          <el-table-column
            prop="finishTime"
            width="90"
            label="完成时间"
          >
            <template slot-scope="{row}">
              {{ formatDateStr(row.finishTime) }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="75px"
          >
            <template slot-scope="{row}">
              <el-link
                v-if="readPerm"
                :underline="false"
                class="mr10"
                type="primary"
                @click="riskDetail(row.id)"
              >
                <el-tooltip
                  content="查看"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-document" />
                </el-tooltip>
              </el-link>
              <el-link
                v-if="delPerm"
                :underline="false"
                type="primary"
                @click="delRisk(row.id)"
              >
                <el-tooltip
                  content="删除"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-delete" />
                </el-tooltip>
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <PageQuery
          v-show="total>0"
          :total="total"
          :cache-name="'listQuery12'"
          :update-state="'UPDATE_LISTQUERY12'"
          :list-query.sync="listQuery"
          :init-list-query.sync="initListQuery"
          @pagination="getList"
        />
      </div>

    </b-card>
  </div>
</template>

<script>
import pagination from '@core/components/pagination/Pagination'
import PageTotalChart from '@/@core/components/page-total-chart/PageTotalChart'
import {
  GetRiskClearListByPage,
  GetClearRiskCountSatisfaction,
  DeleteRiskManageByIds,
  GetAllRiskTypes,
  GetAllRiskSources,
  GetAllRiskObjects, RiskPage,
} from '@/api/risk/risk'
import {
  error, findBtnPerm, success, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'
import FilterSelectList from '@core/components/filter-select-list/FilterSelectList'
import TableHeaderFilterDropdown from '@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown'
import RiskStatistics from '@/views/risk/components/RiskStatistics'
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'
import TableTips from '@core/components/table-tips/TableTips'

export default {
  components: {
    pagination,
    FilterSelectList,
    TableHeaderFilterDropdown,
    PageIntroduce,
    RiskStatistics,
    PageTotalChart,
    TableTips,
  },
  data() {
    const listQuery = {
      SearchStr: '',
      orderBy: {
        riskLevel: '',
        riskObject: '',
        riskSource: '',
        riskStatus: '',
        riskType: '',
      },
      page: 1,
      pageSize: 10,
      riskLevel: 0,
      riskObject: '',
      riskSource: '',
      riskStatus: 0,
      riskType: '',
    }
    return {
      riskStatusObj: {
        6: '处理完成',
        7: '关闭',
        8: '自动关闭',
      },
      pieLabels1: [],
      pieServise1: [],
      pieLabels2: [],
      pieServise2: [],
      pieLabels3: [],
      pieServise3: [],
      riskTotalInit: false,
      pieChartInit1: false,
      pieChartInit2: false,
      pieChartInit3: false,
      selectList: [],
      levelColorObj: {
        5: 'danger',
        4: 'warning',
        3: 'light-warning',
        2: 'success',
      },
      levelObj: {
        2: '低',
        3: '中',
        4: '高',
        5: '严重',
      },
      riskStatusOptions: [
        { label: '处理完成', value: 6 },
        { label: '关闭', value: 7 },
        { label: '自动关闭', value: 8 },
      ],
      levelOptions: [
        { label: '低', value: 2 },
        { label: '中', value: 3 },
        { label: '高', value: 4 },
        { label: '严重', value: 5 },
      ],
      readPerm: findBtnPerm('risk-history-2'),
      delPerm: findBtnPerm('risk-history-3'),
      loading: false,
      total: 0,
      listQuery: { ...listQuery },
      initListQuery: { ...listQuery },
      tableData: [],
      levels: [],
      products: [],
      riskSourceOptions: [],
      riskObjectOptions: [],
      riskTypeOptions: [],
      barSeriesArr: [],
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.readPerm = verifyBtnPerm(btnPerms, 'risk-history-2')
      this.delPerm = verifyBtnPerm(btnPerms, 'risk-history-3')
    })
    this.getAllRiskTypes()
    this.getAllRiskSources()
    this.getAllRiskObjects()
    const cacheQuery = this.$store.state.pageQuery.listQuery12
    this.listQuery = { ...cacheQuery }
    this.getList()
    this.getClearRiskCountSatisfaction()
  },
  methods: {
    getAllRiskSources() {
      GetAllRiskSources().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          resData.data.forEach(r => {
            if (r) {
              this.riskSourceOptions.push({
                label: r,
                value: r,
              })
            }
          })
        }
      })
    },
    getAllRiskTypes() {
      GetAllRiskTypes().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          resData.data.forEach(r => {
            if (r) {
              this.riskTypeOptions.push({
                label: r,
                value: r,
              })
            }
          })
        }
      })
    },
    getAllRiskObjects() {
      GetAllRiskObjects().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          resData.data.forEach(r => {
            if (r) {
              this.riskObjectOptions.push({
                label: r,
                value: r,
              })
            }
          })
        }
      })
    },
    // 风险等级占比
    getClearRiskCountSatisfaction() {
      this.barSeriesArr = []
      GetClearRiskCountSatisfaction().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.barSeriesArr = [
            resData.data.total,
            resData.data.seriousRiskCount,
            resData.data.heightRiskCount,
            resData.data.middleRiskCount,
            resData.data.lowRiskCount,
            // resData.data.veryLowRiskCount,
          ]
        }
      })
    },
    showTips(obj, row, text, type) {
      const TemporaryTag = document.createElement('span')
      TemporaryTag.innerText = text
      TemporaryTag.className = 'getTextWidth'
      document.querySelector('body').appendChild(TemporaryTag)
      const currentWidth = document.querySelector('.getTextWidth').offsetWidth
      document.querySelector('.getTextWidth').remove()
      const cellWidth = obj.target.offsetWidth
      if (type === 1) {
        currentWidth <= (2 * cellWidth) ? row.showTooltip = false : row.showTooltip = true
      } else {
        currentWidth <= (2 * cellWidth) ? row.showExplanation = false : row.showExplanation = true
      }
    },
    delRisk(id) {
      this.$confirm('是否确认删除该风险?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        DeleteRiskManageByIds({ ids: [id] }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.getList(1)
          } else {
            error(resData.msg)
          }
        })
      }).catch(() => { })
    },
    riskDetail(id) {
      this.$router.push({
        path: '/risk-histroydetail',
        query: {
          id,
        },
      })
    },
    getList(flag) {
      this.loading = true
      this.listQuery.riskLevel = Number(this.listQuery.riskLevel)
      this.listQuery.riskStatus = Number(this.listQuery.riskStatus)
      RiskPage(3, this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
        this.riskTotalInit = true
        if (flag) {
          this.getClearRiskCountSatisfaction()
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    sortChange(column) {
      const order = column.order ? column.order : 'ascending'
      const orderObj = { descending: 'DESC', ascending: 'ASC' }
      this.listQuery.orderField = column.column.sortBy
      this.listQuery.order = orderObj[order]
      this.getList()
    },
  },
}
</script>

<style>
.myNote {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.risk-statistics {
  display: flex;
  justify-content: space-evenly;
}
</style>
